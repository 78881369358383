.form-group .autocomplete-search input.p-autocomplete-input {
  text-indent: 20px; /* indent so icon doesn't overlap */
}

.form-group .autocomplete-search .p-autocomplete::before {
  font-family: 'Font Awesome 6 Pro';
  color: var(--textfield-icon-primary);
  position: absolute;
  left: 8px;
  content: "\f002";
  top: 10px;
}

.form-group .autocomplete-search .p-autocomplete .p-autocomplete-dropdown {
  right: 0;
}

.autocomplete-search .p-autocomplete-items .p-autocomplete-list-item {
  line-height: 30px;
  padding: 6px 0;
}

.form-group .autocomplete-search .p-autocomplete-dd .p-autocomplete-input {
  padding-right: 40px;
}

.p-autocomplete-item .wrap {
  white-space: normal;
}
