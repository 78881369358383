.p-chip.p-chip-analytics {
  margin: 0;
}

.small.p-chip{
  .p-chip-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
  }
}
