@font-face {
  font-family: Inter;
  src: url(assets/css/fonts/Inter/Inter-VariableFont_slnt,wght.ttf) format("opentype");
}

// Changes the font family
$font-family: "Inter", -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

@import 'assets/sass/variables';

// Import all internal and vendor CSS
@import "assets/bootstrap/css/bootstrap.css";
@import "assets/bootstrap/css/_overrides.scss";
@import "assets/css/bootstrap-material-design.min.css";
@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-alpine.css";
@import "assets/css/dashboard.css";
@import "assets/css/dashboard-black.css";
@import "assets/css/dashboard-min.css";
@import "assets/css/black-theme.css";
@import "assets/css/d3styles.css";
@import "assets/css/build.css";
@import "assets/fonts/OpenSans/open-sans.css";
@import "assets/css/spinner.css";
@import "primeng/resources/primeng.min.css";
@import "@oat/opl-theme/src/theme/theme.css"; // OPL theme overriding PrimeNG
@import "assets/primeng/main.scss"; // Eclipse overrides of PrimeNG styles
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.scss";
@import "assets/primeng/overrides";
@import "assets/fontawesome/custom"; // Custom icons for Fontawesome
@import 'ngx-toastr/toastr';
@import 'assets/opl/sass/main';
@import "assets/css/styles";

:root {
  ::-webkit-scrollbar {
    background: transparent;
    border: rgba(#F4F5F7, 0.2) solid 0;
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

html .dark {
  &::-webkit-scrollbar, ::-webkit-scrollbar {
    background: #383C42;
    border: rgba(#F4F5F7, 0.2) solid 0;
  }
  &::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255, .15);
  }
  &::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255,255,255, 0.3);
  }
  &::-webkit-scrollbar-thumb:active, ::-webkit-scrollbar-thumb:active {
    background-color: rgba(255,255,255, .5);
  }
}

/*Remove padding on notification toasts that cause the icon to float off the div*/
.simple-notification .sn-content {
  padding: 0 0 0 0 !important;
}

.import-description-text {
  font-size: 14px;
  margin-left: 5px;
}

.import-download-text {
  margin: 10px 0px 5px 0px;
  text-align: end;
}

.import-new-text {
  text-align: end;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

eclipse-extended-grid-tool-panel,
eclipse-quick-trade-tool-panel,
eclipse-model-analyze-tool-panel {
  width: 100%;
}

.toast-container .ngx-toastr {
  padding: 0;
  box-shadow: none;
}

.hidden-element {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


@media (min-width: 1440px) {
  #main-app-content .container {
    max-width: 1430px
  }
}

[class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: inherit;
}

#main-app-content {
  .collapsed-content {
    height: 100%;
    width: calc(100% - 250px);
    position: relative;
    left: 250px;
    transition: 200ms ease;
    padding: 0;
  }

  .expanded-content {
    height: 100%;
    width: calc(100% - 64px);
    position: relative;
    left: 64px;
    transition: 200ms ease;
    padding: 0;
  }

  .collapse {
    height: max-content;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -moz-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }
}

#main-app-content {
  height: 100%;

  .bs4-button {
    background-position: 0 0;
    background-image: none;
    text-shadow: none;
    box-shadow: none;
    height: 32px;
    width: 96px;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .sidebar-button {
    text-align: center;
    width: 32px;
    border-radius: 10px;
    color: var(--button-text-secondary);
    border: 1px solid var(--button-stroke-secondary);
    background-color: var(--button-background-secondary);
  }

  .sidebar-button:hover {
    background-color: var(--button-background-secondary-hover);
  }

  .sidebar-button-text {
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    position: absolute;
    bottom: 17px;
    left: 55px;
  }

  .sidebar-button-text:hover {
    cursor: pointer;
  }

  .sidebar-button i {
    color: var(--button-icon-secondary);
  }

  .sidebar-footer-padding {
    padding: 8px 24px 8px 12px;
  }

  .super-admin-preference {
    font-weight: 900;
  }
}

.loading-cell-value:after {
  /*fas fa-spinner fa-pulse fa-fa*/
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  font-family: 'Font Awesome 6 Pro';
  content: "\f254";
}

.ag-rich-select .ag-rich-select-list {
  height: 100%;
}
