.ui-dialog-xs .p-dialog, .p-dialog.ui-dialog-xs {
  width: 200px;
}

.ui-dialog-sm .p-dialog, .p-dialog.ui-dialog-sm-xl {
  width: 300px;
}

.ui-dialog-md .p-dialog, .p-dialog.ui-dialog-md {
  width: 600px;
}

.ui-dialog-lg .p-dialog, .p-dialog.ui-dialog-lg {
  width: 900px;
}

.ui-dialog-xl .p-dialog, .p-dialog.ui-dialog-xl {
  width: 990px;
}

.ui-dialog-xxl .p-dialog, .p-dialog.ui-dialog-xxl {
  height: calc(90vh);
  width: calc(50vw);
}

.ui-dialog-fullwidth .p-dialog, .p-dialog.ui-dialog-fullwidth {
  width: 100%;
}

.p-dialog .p-dialog-content {
  color: var(--modal-text-primary); /* input text in dialogs should be primary, not secondary */
}

.p-dialog .p-content,
.p-dialog .p-footer {
  padding: 1em;
}

.p-dialog .p-footer {
  border-top: 1px solid var(--modal-stroke-primary);
  color: var(--text-primary);
  text-align: right;
  clear: left;
}

.p-dialog .p-footer button {
  margin: 0 0.5rem 0 0;
}
.p-dialog .p-dialog-footer button:last-child {
  margin-right: 0;
}

p-dynamicdialog .p-dialog .p-dialog-footer {
  padding-left: 0;
  padding-right: 0;
}

.p-dialog-mask {
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
}
