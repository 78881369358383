.text-wrap-with-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

h4 {
  margin: 0;
}

/* Default compressed (low padding, shorter rows) mode */
.ag-theme-alpine {
  --ag-grid-size: 5px;
  --ag-tooltip-background-color: var(--gray-200);
  --ag-borders-row: var(--ag-borders); // missing from ag-grid balham theme, even though it's referenced :-/
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: var(--ag-line-height);
  }

  & .ag-header-row-column-filter .ag-header-cell {
    padding: 0 16px;
  }

  & .ag-header-row-column .ag-header-cell {
    padding: 0 2px 0 16px;
  }

  & .ag-cell.ag-cell-value {
    padding-left: 16px;
    padding-right: 16px;
    line-height: 28px !important;

    &.ag-cell-inline-editing {
      padding-left: 0;
      padding-right: 0;
      height: initial;
    }
  }

  & .ag-header-row.ag-header-row-column-filter {
    height: 26px !important;
    min-height: 26px !important;
  }

  & .ag-row {
    line-height: 28px;
    height: 29px !important;
    min-height: 29px !important;
  }

  & .ag-header-row {
    text-transform: initial;
    height: 29px !important;
  }
}

/* Compressed (low padding/margin) mode */
.ag-compressed .ag-theme-alpine {
  & .ag-cell.ag-cell-value {
    padding-left: 11px;
    padding-right: 11px;
    line-height: 24px !important;

    &.ag-cell-inline-editing {
      padding-left: 0;
      padding-right: 0;
      height: initial;
    }
  }

  & .ag-header-cell {
    padding: 0 5px;
  }

  & .ag-header-row.ag-header-row-column-filter {
    height: 24px !important;
    min-height: 24px !important;

    & .ag-floating-filter-full-body {
      align-items: baseline;
    }
  }

  & .ag-row {
    line-height: 24px;
    height: 24px !important;
    min-height: 24px !important;
  }
}

.ag-theme-alpine .ag-popup-child {
  z-index: 9; /* dropdowns in column header popups need to be moved above their parent */
}

.ag-menu {
  z-index: 9;
}

.ag-dnd-ghost {
  display: inline-flex; /* OPL breaks column drag/drop by setting display to none, so put it back. */
}

/* Row background color for new trades */
.ag-theme-alpine {
  & .ag-row.new-trade-row {
    background-color: var(--purple-200);
  }
}

.dark {
  & .ag-theme-alpine {
    & .ag-row.new-trade-row {
      background-color: var(--indigo-100);
    }
  }
}

.portfolio-summary {
  margin: 0 15px;
  padding-top: 4px;
  border-bottom: 2px solid var(--grid-stroke-primary);

  & .information-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  & .tolerance-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* Columns headers with multiple lines and word wrapping */
.multi-line-row-header-grid {
  & .ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  & .ag-header-row.ag-header-row-column {
    height: 48px !important;
  }
}

.grid-wrap-header .ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.account-detail-grid {
  & .ag-theme-alpine {
    min-height: unset !important;
  }

  & .ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  & .ag-header-row.ag-header-row-column {
    height: 48px !important;
  }

  & .ag-header-cell {
    padding: 0 4px;
  }
}

.trade-detail-grid {
  & .ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  & .ag-header-row.ag-header-row-column {
    height: 48px !important;
  }

  & .ag-header-cell {
    padding: 0 4px;
  }
}

.header-text-hidden .ag-header-cell-label .ag-header-cell-text {
  display: none;
}

.compare-icon.fa.fa-eye {
  cursor: pointer;
}

.tactical-subcomponent-header {
  text-transform: uppercase;
  color: var(--text-secondary);
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  display: inline;
}

.label-red {
  color: var(--red-500);
}

.label-green {
  color: var(--green-500);
}

.label-yellow {
  color: var(--yellow-500);
}

.model-tolerance-level-select-button {
  .p-selectbutton {
    .p-button {
      height: 2rem;
      padding: 1.15rem 0;
      margin: 0 1rem;
      background: unset;
      box-shadow: unset;
      font-size: 0.875rem;
      border-width: 0 0 2px 0;
      border-radius: 0;
      border-style: solid;
      border-color: transparent;

      &.p-highlight {
        border-bottom: 2px solid var(--tab-horizontal-stroke-primary);
        background: unset;
      }
      &:not(.p-highlight):hover {
        border-bottom: 2px solid var(--tab-horizontal-stroke-hover);
      }

      &:after {
        content: '';
        border-style: solid;
        border-color: transparent;
        border-width: 0 12px 12px 0;
        right: 0;
        top: 0;
        position: absolute;
      }

      &:has(.oot):after {
        border-color: transparent var(--red-500) transparent transparent;
      }

      &:has(.in-tolerance):after {
        border-color: transparent var(--green-500) transparent transparent;
      }
    }
    .p-button:has(.p-selectbutton-hidden) {
      display:none;
    }


  }
}

.tactical-tool {
  height: 100% !important;
  top: 0;
}

.tactical-tool .ag-cell-data-changed {
  background-color: var(--yellow-500) !important;
}

/* to prevent column panel showing double scrollbars when the grid is < 400px tall */
.tactical-tool .ag-column-panel-center {
  min-height: unset;
}

.tactical-tool .model-tolerance-main .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  background: none;
}

.tactical-tool .model-tolerance-main .p-tabview-nav > li a {
  &:after {
    content: '';
    border-style: solid;
    border-color: transparent;
    border-width: 0 12px 12px 0;
    right: 0;
    top: 0;
    position: absolute;
  }

  &.oot:after {
    border-color: transparent var(--red-500) transparent transparent;
  }

  &.in-tolerance:after {
    border-color: transparent var(--green-500) transparent transparent;
  }
}

.tactical-tool .model-tolerance-main a.tactical-model-cash-link {
  color: var(--grid-text-primary);
  font-weight: 600;
}

.tax-deferred-account {
  background-color: var(--green-200);
}

.tax-exempt-account {
  background-color: var(--blue-200);
}

.sma-account {
  background-color: var(--yellow-300);
}

.do-not-trade-account {
  border: 2px solid var(--red-500) !important;
}

.do-not-buy-sell {
  border: 2px solid var(--red-500) !important;
}

.excluded-security {
  border: 4px solid var(--black) !important;
}

.positive-amount {
  color: var(--green-500);
}

.negative-amount {
  color: var(--red-500);
}

.out-of-tolerance-cell {
  background-color: var(--red-200);
}

.tactical-tolerance-status-icon {

}
.trade-warning {
  border: 2px solid var(--red-500) !important;
}
.tactical-legend-item {
  display: flex;
  align-items: center;
}

.tactical-legend-swatch {
  width: 15px;
  height: 15px;
  margin-right: 7px;
  border: 1px solid var(--menu-stroke-primary);
}

.tactical-legend-icon {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}

.tactical-legend-icon > .fas, .tactical-legend-icon > .far {
  font-size: 16px;
}

.tactical-legend-symbol {
  width: 15px;
  height: auto;
  margin-right: 7px;
  text-align: center;
}

.tactical-notes-tooltip {
  max-width: 50%;
}

.editable-cell {
  border-bottom: 1px solid var(--gray-500) !important;
  background: rgba(var(--gray-500), .6);
}

/* Mozilla hack for inputs in flexbox not shrinking
https://bugzilla.mozilla.org/show_bug.cgi?id=1242914
*/
input {
  min-width: 0;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.button-footer button, .button-footer a {
  margin: 0 0.5rem 0 0;
}

.btn-bar button {
  margin: 0 0.5rem 0 0;
}

.stress-test-container {
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
  max-height: 414px;
  margin-right: -5px;
}

.stress-test-container ul {
  margin: 0px;
  padding: 0px;
}

.stress-test-container li {
  padding: 8px 0;
  list-style: none;
  float: left;
  width: 100%;
  background-color: var(--select-background-base);
  border-top: 1px solid var(--select-stroke-primary);
  border-bottom: 1px solid var(--select-stroke-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > span {
    padding: 5px 5px;
    text-align: left;
  }

  &:hover {
    background-color: var(--select-background-option-hover);
    cursor: pointer;
  }

  &.active {
    background-color: var(--select-background-option);
  }
}

.stress-test-situation-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 337px;
  overflow: auto;
}

.stress-test-situation-container ul li {
  border: 1px solid var(--grid-stroke-primary);
  color: var(--grid-text-primary);
  border-radius: var(--border-radius);
  padding: 16px;

  &:hover {
    background-color: var(--grid-background-hover);
    color: var(--grid-text-hover);
  }

  &.selected {
    background-color: var(--grid-background-selected);
    color: var(--grid-text-selected);
  }
}

/* space between adjacent buttons */
.p-button + .p-button:not(.p-splitbutton-menubutton):not(.btn-block):not(.p-selectbutton .p-button):not(.p-picklist-buttons .p-button) {
  margin-left: 5px;
}

.topbar {
  background: var(--app-background) fixed;
  z-index: 980;
  padding: 6px 0 0 248px;
  position: fixed;
  top: 40px;
  width: 100%;
  right: 0;
}

.sm-wrapped-page .topbar {
  right: unset;
  left: 0;
  width: calc(100% - 270px)
}

.expanded-content .topbar {
  width: 100%;
  right: 0;
  padding-left: 64px;
}

.sm-wrapped-page .expanded-content .topbar {
  right: unset;
  left: 0;
  width: calc(100% - 270px)
}

.opl-editor-content-container .topbar {
  padding-left: 0px;
  top: 0px;
}

@media (max-width: 768px) {
  .visible-ipad {
    display: none;
  }
}

@media (min-width: 1446px) {
  .visible-ipad {
    display: none;
  }
}

@media (min-width: 1090px) and (max-width: 1446px) {
  .hidden-ipad {
    display: none;
  }
}

@media (min-width: 1081px) and (max-width: 1510px) {
  body.sm-chat.sm-engaged.sm-engaged-right .hidden-ipad {
    display: none;
  }
}

.dropdown-menu > li > a > i {
  margin-right: 0.5rem;
}

.custom-import-history-chip {
  font-size: 12px;
  font-weight: 600;
  min-inline-size: 130px;
  height: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
}

.autocomplete-text-highlight {
  color: var(--filter-text-link);
}

.portfolio-hierarchy {
  & .p-dropdown-items-wrapper {
    min-width: 600px;
  }

  .hierarchy-item {
    display: flex;
    justify-content: space-between;
  }
  .hierarchy-level-1 {
  }
  .hierarchy-level-2 {
    margin-left: 25px;
  }

  & .p-dropdown-item:not(.p-highlight), & .p-dropdown-label {
    .portfolio-text-color {
      color: var(--gray-500);
    }
    .portfolio-tree-icon {
      color: var(--gray-500);
    }
    .account-text-color {
      color: var(--accounttree-text-account);
    }
    .account-tree-icon {
      color: var(--accounttree-icon-account);
    }
  }
}

eclipse-tradeorder-model-analysis .out-of-tolerance-row .ag-cell-value {
  color: var(--red-400) !important;
}

.entity-icon-portfolio {
  color: var(--gray-500);
}
.entity-icon-account {
  color: var(--accounttree-icon-account);
}

.thumbnail {
  background: var(--card-background-base);
  border: var(--avatar-stroke-primary);
  & > img {
    margin-left: inherit;
    margin-right: inherit;
  }
}

caption {
  color: var(--table-text-primary);
}

.audit-history-value-changed:not(:has(.ag-cell-value)),
.audit-history-value-changed .ag-cell-value {
  background-color: var(--blue-200);
}

.favorite-star {
  color: var(--button-stroke-secondary);
  &.active {
    color: var(--yellow-600);
  }
}

.color-transparent {
  color: transparent;
}
